import Layout from "../components/layout"
// import { Instructor1 } from "../images/sruthi.jpg"
import "../styles/instructors.css"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function Instructors() {
  return (
    <Layout route="/instructors">
      <div className="instructorPage">
        <h1 className="meetHead">MEET OUR INSTRUCTORS</h1>
        <div className="insSection first">
          <h3>Dr. Anjana Pattali</h3>
          <div className="line">.</div>
          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-3 md:col-start-2">
              <StaticImage
                src="../../images/meetInstructor.jpg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="instructor"
                className="inset-0"
              />
            </div>
            <div className="col-span-10 col-start-2 md:col-span-6 md:col-start-6 flex align-items-center">
              <p>
                BAMS, Msc Yoga Therapy,
                <br />
                Founder &Director , Antaryami <br />
                <br />A young passionate yoga trainer & ayurveda doctor from
                North Kerala. She completed her graduation in Ayurveda Medicine
                and Surgery from Kerala University of Health Sciences (KUHS) and
                Post Graduation in Yoga Therapy from Central University of
                Kerala.. She is also an AYUSH Certified Yoga Trainer. Apart from
                the academic fields she is interested and trained in classical
                dance & music. Currently she leads the” Antaryami” with
                indomitable spirit and enthusiasm.
              </p>
            </div>
          </div>
        </div>
        <div className="insSection">
          <h3>Sruthi A</h3>
          <div className="line">.</div>

          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-3 md:col-start-2 sruthi">
              {/* <img src={Instructor1} alt="instructor" /> */}
              <StaticImage
                src="../../images/sruthi.jpg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="instructor"
                className="inset-0 w-full "
                
              />
            </div>
            <div className="col-span-10 col-start-2 md:col-span-6 md:col-start-6 flex align-items-center">
              <p>
                Msc Yoga Therapy,
                <br />
                <br />
                Sruthi is a vibrant yoga trainer with an intense academic
                background in Yoga & Management. She completed her Post
                Graduation in MSc Yoga Therapy with third Rank from Central
                university of Kerala in the year. She also holds an MBA Masters
                Degree from Bharathiar University, Coimbatore.
              </p>
            </div>
          </div>
        </div>
        <div className="insSection">
          <h3>Shivapriya</h3>
          <div className="line">.</div>

          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-3 md:col-start-2">
              <StaticImage
                src="../../images/shivapriya.jpg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="instructor"
                className="inset-0"
              />
            </div>
            <div className="col-span-10 col-start-2 md:col-span-6 md:col-start-6 flex align-items-center">
              <p>
                Msc Yoga Therapy,
                <br />
                <br />
                Originally from North Kerala, Shivapriya is a dedicated yoga
                trainer with a lot of energy. She graduated with honours from
                the Central University of Kerala, Periye, with a master's in
                Yoga Therapy. She has a reasonable amount of expertise in
                managing yoga training in an online environment, having done so
                for the past two years.
              </p>
            </div>
          </div>
        </div>
        <div className="insSection">
          <h3>Akhila CK</h3>
          <div className="line">.</div>

          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-3 md:col-start-2">
              <StaticImage
                src="../../images/akhila.jpeg"
                quality={100}
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="instructor"
                className="inset-0"
              />
            </div>
            <div className="col-span-10 col-start-2 md:col-span-6 md:col-start-6 flex align-items-center">
              <p>
                Msc Yoga Therapy,
                <br />
                <br />
                After completing her undergraduate degree in physics, Akhila
                decided to pursue yoga full-time and has been practising it ever
                since. She has a lot of experience in yoga training, having done
                it for several years. From Kerala's Central University of
                Kerala, she received her postgraduate degree in Yoga Therapy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Instructors
